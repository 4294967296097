export default ({
  images = {},
}) => {
  return {
    images: images,
    currentImage: '',
    init() {


      this.getRandomImage();

      this.$el.addEventListener('click', () => {
        this.getNextImage();
      }) 
    },

    getRandomImage(){
      this.currentImage = this.images[Math.floor(Math.random() * this.images.length)];
    },
    getNextImage(){
      let currentIndex = this.images.indexOf(this.currentImage);
      let nextIndex = currentIndex + 1;
      if(nextIndex >= this.images.length){
        nextIndex = 0;
      }
      this.currentImage = this.images[nextIndex];
    }
  }
}