import gsap from 'gsap';

export default () => {
  return {
    init() {

      let imageTimeline = gsap.timeline({paused:true});
      let duration = 1.5;
      let pixilationSize = 10;

      imageTimeline.to(this.$refs.pixel, {
        duration: duration,
        attr: {
          height:pixilationSize * 2,
          width:pixilationSize * 2,
        }
      }, 0);

      imageTimeline.to(this.$refs.morph, {
        duration: duration,
        attr: {
          radius:pixilationSize,
        }
      }, 0);

      this.$el.addEventListener('mouseenter', () => {
        imageTimeline.play();
      }) 

      this.$el.addEventListener('mouseleave', () => {
        imageTimeline.reverse();
      }) 
    }
  }
}
