import 'lite-youtube-embed'
import 'lite-vimeo-embed/module/lite-vimeo-embed'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

import Alpine from 'alpinejs'
// import Collapse from '@alpinejs/collapse'
// import Focus from '@alpinejs/focus'
import Intersect from '@alpinejs/intersect'

import Splide from '@splidejs/splide';
import 'virtual:svg-icons-register'
import.meta.glob('../img/**/*')

gsap.registerPlugin(ScrollTrigger);

import gsapMarquee from './components/gsapMarquee'
import gsapImageHover from './components/gsapImageHover'
import splideSlider from './components/splideSlider'
import splideWorkSlider from './components/splideWorkSlider'
import gsapPixilate from './components/gsapPixilate';
import imageRandomizer from './components/imageRandomizer'

import '~/css/app.css'

Alpine.plugin(Intersect)

Alpine.data('gsapMarquee', gsapMarquee)
Alpine.data('gsapImageHover', gsapImageHover)
Alpine.data('splideSlider', splideSlider)
Alpine.data('splideWorkSlider', splideWorkSlider)
Alpine.data('gsapPixilate', gsapPixilate)
Alpine.data('imageRandomizer', imageRandomizer)

window.Alpine = Alpine

export function init() {
  const APP = window.APP || {
    CONFIG: {},
  }

  window.APP = {
    ...APP,
    Alpine,
    // Alpine components
    components: {},
    // Other widgets / modules
    modules: {},
  }

  Alpine.start()
  
  let boxes = gsap.utils.toArray('.js-contentSection');
  let loader = document.querySelector('.js-fullpageLoader');
  console.log(loader);
  let tl = gsap.timeline()

  //Navigation gsapping
  tl.to('#navigation', {opacity: 1, top: 20, duration: .75, delay: 1.75})

  if(loader) {
    // if we have a loader image show it, then hide it
    tl.to(loader, {
      duration: .55,
      delay: 1,
      opacity: 0,
      height: 0,
      onComplete: () => {
        ScrollTrigger.refresh();
      }
    })
  } 

  boxes.forEach(box => {
    tl.from(box, { 
      opacity: 0,
      y: 100,
      duration: 2.5,
      ease: 'sine.inOut',
      scrollTrigger: {
        trigger: box,
        scrub: 1.5,
        //markers: true,
        start: 'top 90%',
        end: '+=300',
      }
    })
  });

}

window.addEventListener('DOMContentLoaded', init)

if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log('HMR')
  })
}
