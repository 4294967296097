import Splide from '@splidejs/splide'

export default () => {
  return {
    init() {
      new Splide(this.$refs.splide, {
        fixedWidth: 300,
        type: 'loop',
        gap: '20px',
        pagination: false,
      }).mount()
    },
  }
}
