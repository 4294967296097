import { gsap } from 'gsap'

export default ({
  image = false,
}) => {
  return {
    image: image,
    init() {
      let self = this.$refs.gsapImageHover
      let imageUrl = this.$refs.gsapImageHover.dataset.image
      
      if(imageUrl){

        let img = document.createElement('img')
        img.src = imageUrl
        img.classList.add('hidden', 'lg:block', 'js-hover-image', 'opacity-0', 'fixed', 'top-0', 'left-0', 'h-[200px]', 'object-cover', 'object-center', 'z-10')
        img.ariaHidden = true

        self.appendChild(img)

        gsap.set(img, { yPercent: -120, xPercent: 10 })

        const image = self.querySelector('.js-hover-image'),
          setX = gsap.quickSetter(image, 'x', 'px'),
          setY = gsap.quickSetter(image, 'y', 'px'),
          align = (e) => {
            setX(e.clientX)
            setY(e.clientY)
          },
          startFollow = () => document.addEventListener('mousemove', align),
          stopFollow = () => document.removeEventListener('mousemove', align),
          fade = gsap.to(image, { autoAlpha: 1,   duration: .15, ease: 'power2.in', paused: true, onReverseComplete: stopFollow })
        
        self.addEventListener('mouseenter', (e) => {
          fade.play()
          startFollow()
          align(e)
        })
        
        self.addEventListener('mouseleave', () => fade.reverse())
        
        
      }
    },
  }
}
