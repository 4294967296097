import Splide from '@splidejs/splide'

export default () => {
  return {
    init() {
      new Splide(this.$refs.splide, {
        type: 'slide',
        gap: '30px',
        perPage: 4,
        pagination: false,
        drag: true,
        classes: {
          arrows: 'splide__arrows your-class-arrows',
          arrow : 'splide__arrow your-class-arrow',
          prev  : 'splide__arrow--prev your-class-prev',
          next  : 'splide__arrow--next your-class-next',
        },
        breakpoints: {
          1279: {
            perPage: 3,
          },
          640: {
            perPage: 2,
          },
        },
      }).mount()
    },
  }
}
